import React from 'react';
import css from "./textWrapper.module.scss";

const TextWrapper = props => {
  const padding = props.padding || 0;

  return (
    <div className={css.textBoxWrapper} style={{ padding }}>
      {props.children}
    </div>
  );
};

export default TextWrapper;

