import React from 'react';
import css from './pageTitle.module.scss';

const PageTitle = props => {
  const { application = 'Optiwai' } = props;

  const getColorClass = () => {
    switch (application) {
      case 'Optiwai':
        return css.redLine;
      case 'OptiwaiPlus':
      case 'Deeplai':
      default:
        return css.greenLine;
    }
  };

  return (
    <div className={[css.wrapper, getColorClass()].join(' ')}>
      {props.children}
    </div>
  );
};

export default PageTitle;
