import React from 'react';
import css from './illustratedBox.module.scss';
import BoxHeader from "../BoxHeader/BoxHeader";
import Background from "./Background/Background";
import ImagesWrapper from "./ImagesWrapper/ImagesWrapper";
import ImagesContent from "./ImagesContent/ImagesContent";
import Images from "./Images/Images";
import Image from "./Image/Image";
import TextWrapper from "./TextWrapper/TextWrapper";
import TextContent from "./TextContent/TextContent";
import TextHeader from "./TextHeader/TextHeader";
import Text from "./Text/Text";

const IllustratedBox = (props) => {
  const {
    boxHeader,
    boxHeaderLineColor,
    imagesBox,
    imagesPosition = "left",
    textBox
  } = props;

  const getImagesPositionClass = () => {
    switch (imagesPosition) {
      case "top":
        return css.imagesOnTop;
      case "bottom":
        return css.imagesOnBottom;
      case "right":
        return css.imagesOnRight;
      default:
        return css.imagesOnLeft
    }
  };

  return (
    <div className={css.wrapper}>
      {boxHeader &&
      <div className={css.boxHeaderWrapper}>
        <BoxHeader bigger lineColor={boxHeaderLineColor}>
          {boxHeader}
        </BoxHeader>
      </div>
      }
      <div className={[css.content, getImagesPositionClass()].join(' ')}>
        {imagesBox}
        <div className={css.textWrapper}>
          {textBox}
        </div>
      </div>
    </div>
  );
};

export default IllustratedBox;

export {
  IllustratedBox,
  Background,
  ImagesWrapper,
  ImagesContent,
  Images,
  Image,
  TextWrapper,
  TextContent,
  TextHeader,
  Text,
}
