import React from 'react';
import css from "./VerticalLine.module.scss";


const VerticalLine = () => {

  return (

    <div className={css.VerticalLine}>
    </div>
  );
};

export default VerticalLine;
