import React from 'react';
import css from './text.module.scss';

const Text = (props) => {
  return (
    <div className={css.text}>
      {props.children}
    </div>
  );
};

export default Text;
