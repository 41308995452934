import React from 'react';
import css from "./imagesContent.module.scss";

const ImagesContent = props => {
  const width = props.width ? `${props.width}px` : 0;
  const height = props.height ? `${props.height}px` : 0;
  const extraClass = props.className || '';

  return (
    <div className={[css.imagesInner, extraClass].join(' ')} style={{ width, height }}>
      {props.children}
    </div>
  );
};

export default ImagesContent;

