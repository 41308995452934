import React from 'react';
import css from "./imagesWrapper.module.scss";

const ImagesWrapper = props => {
  const pTop = props.top;
  const top = pTop ? `${pTop}px` : 0;
  const marginBottom = (pTop && parseInt(pTop) && parseInt(pTop) > 0) ? pTop : 0;

  const pLeft = props.left;
  const marginLeft = (pLeft && parseInt(pLeft)) || 'auto';

  const pRight = props.right;
  const marginRight = (marginLeft === 'auto' && pRight && parseInt(pRight)) || 'auto';

  const centerClass = !!props.center ? css.center : '';

  const coveredByText = !!props.coveredByText;

  return (
    <div
      className={[css.imagesWrapper, centerClass].join(' ')}
      data-coveredbytext={coveredByText}
      style={{ top, marginBottom, marginLeft, marginRight }}
    >
      {props.children}
    </div>
  );
};

export default ImagesWrapper;

