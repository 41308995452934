import React from 'react';
import css from "./background.module.scss";

const Background = props => {
  const mapProp = propName => {
    return (props[propName] !== undefined) ? `${props[propName]}px` : 'auto'
  };

  const top = props.top ? `${props.top}px` : 0;
  const left = mapProp('left');
  const right = mapProp('right');
  const width = props.width ? `${props.width}px` : 0;

  return (
    <div className={css.background} style={{ top, left, right, width }}>
      {props.children}
    </div>
  );
};

export default Background;

