import React from 'react';
import css from "./image.module.scss";

const Image = props => {
  const mapProp = propName => {
    return (props[propName] !== undefined) ? `${props[propName]}px` : 'auto'
  };

  const top = mapProp('top');
  const bottom = mapProp('bottom');
  const left = mapProp('left');
  const right = mapProp('right');
  const width = props.width ? `${props.width}px` : 0;
  const height = props.height ? `${props.height}px` : 0;
  const transform = props.transform || 'none';
  const extraClass = props.className || '';

  return (
    <div className={[css.imageWrap, extraClass].join(' ')} style={{ top, bottom, left, right, width, height, transform }}>
      {props.children}
    </div>
  );
};

export default Image;

