import React, {useEffect, useState} from "react";
import {breakpoints} from "../componentsUtils";

function useScale(baseScale = 1, maxScale = null) {
  const isClient = typeof window === 'object';

  const getWindowWidth = () => {
    return isClient ? window.innerWidth : undefined;
  };

  const smallestContainerWidth = breakpoints.xs;
  const baseContainerWidth = breakpoints.lg;

  const [windowWidth, setWindowWidth] = useState(baseContainerWidth);
  const [scale, setScale] = useState(baseScale);

  const calcCurrentContainerWidth = currentWindowWidth => {
    if (currentWindowWidth >= baseContainerWidth) {
      return baseContainerWidth;
    }
    if (currentWindowWidth <= smallestContainerWidth) {
      return smallestContainerWidth;
    }
    return currentWindowWidth;
  };

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      const currentWindowWidth = getWindowWidth();
      const currentContainerWidth = calcCurrentContainerWidth(currentWindowWidth);
      const newScale = (baseScale * currentContainerWidth) / baseContainerWidth;
      const newOrMaxScale = (maxScale && newScale > maxScale) ? maxScale : newScale;
      setWindowWidth(currentWindowWidth);
      setScale(newOrMaxScale);
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[scale, windowWidth]);

  return scale;
}


export default useScale;
