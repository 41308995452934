import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import css from './solutions.module.scss';
import Footer from "../components/shared/Footer/Footer";
import OptiwAIPlusLogo from "../images/logos/optiwai_plus_full_logo.svg";
import About1Img from "../images/sections/optiplusAbout/about_1.svg";
import About1MobileImg from "../images/sections/optiplusAbout/about_1_m.svg";
import WorkflowAnimation1Img from "../images/sections/optiplusAbout/workflow_animation_1.gif";
import WorkflowAnimation2Img from "../images/sections/optiplusAbout/workflow_animation_2.gif";
import WorkflowAnimation3Img from "../images/sections/optiplusAbout/workflow_animation_3.gif";
import SignUpButton from "../components/shared/SignUpButton/SignUpButton";
import {getRoutes, optiPlusLoggingEnabled, fastTrackPortalsEnabled} from "../components/shared/routes";
import {graphql, useStaticQuery} from 'gatsby';
import OptimizationOptiplus from "../components/sections/optimizationOptiplus/OptimizationOptiplus";
import ArtifactRemoving from "../components/sections/artifactRemoving/ArtifactRemoving";
import OptiplusExploreHero from "../components/sections/optiplusExploreHero/OptiplusExploreHero";
import Upscaling from "../components/sections/upscaling/Upscaling";
import AnonymisationOptiplus from "../components/sections/anonymisationOptiplus/AnonymisationOptiplus";
import AutoCroppingOptiplus from "../components/sections/autoCroppingOptiplus/AutoCroppingOptiplus";
import MetadataOptiplus from "../components/sections/metadataOptiplus/MetadataOptiplus";
import RotationSizeModificationOptiplus from "../components/sections/rotationSizeModificationOptiplus/RotationSizeModificationOptiplus";
import FileFormatsConversion from "../components/sections/fileFormatsConversionOptiplus/FileFormatsConversionOptiplus";
import FaceCroppingOptiplus from "../components/sections/FaceCroppingOptiplus/FaceCroppingOptiplus";
import AgedPhotoOptiplus from "../components/sections/AgedPhotoOptiplus/AgedPhotoOptiplus";
import VerticalLine from "../components/sections/VerticalLine/VerticalLine";

import CarsLogo from '../images/logos/fastTrack/cars_logo.svg';
import FastTrackLogo from '../images/logos/fastTrack/fast_track_icon.svg';
import RestorationLogo from '../images/logos/fastTrack/restoration_logo.svg';
import WebPublishingLogo from '../images/logos/fastTrack/web_publishing_logo.svg';
import RealEstateLogo from '../images/logos/fastTrack/real_estate_logo.svg';
import MainButton from "../components/shared/MainButton/MainButton";


const SolutionsPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            webAppUrl
            quickPortals {
              webPublishing
              cars
              realEstate
              restoration
            }
          }
        }
      }
    `
  );

  const pageTitle = `${getRoutes().Solutions.pageTitle}`;


  const renderGifWrapper = (index, image, text1, text2) => {
    return (
        <div className={css.gifWrapper}>
            <div className={css.image}>
              <img src={image} alt="" />
            </div>
            <div className={css.text}>
                <div className={css.text1}>
                    {text1}
                </div>
                <div className={css.text2}>
                    {text2}
                </div>
            </div>
        </div>
    );
    };

  const quickPortalsUrls = site.siteMetadata.quickPortals;


  const fastTrackMenuItems = [{
    logo: WebPublishingLogo,
    url: quickPortalsUrls.webPublishing,
    mobileLabel: "Web Publishing",
    keyRender: 'fast_track_web_publishing'
  }, {
    logo: CarsLogo,
    url: quickPortalsUrls.cars,
    mobileLabel: "Cars",
    keyRender: 'fast_track_cars'
  }, {
    logo: RealEstateLogo,
    url: quickPortalsUrls.realEstate,
    mobileLabel: "Real Estate",
    keyRender: 'fast_track_real_estate'
  }, {
    logo: RestorationLogo,
    url: quickPortalsUrls.restoration,
    mobileLabel: "Aged Photos",
    keyRender: 'fast_track_restoration'
  }];

  const renderFastTrackDesktopMenuItem = item => {
    const { logo, url, keyRender } = item;
    return (
        <a href={url} target="_blank" className={css.fastTrackMenuItem} key={keyRender}>
          <div className={css.logoWrapper}>
            <img src={logo} alt=""/>
          </div>
          <MainButton
              label={<div className={css.textButton}>Learn more</div>}
              isLink={false}
              filled
              color={'green'}
              className={css.solutionsSubmenuButton}
          />
        </a>
    );
  };

  const renderFastTrackDesktopMenuItems = () => {
    return fastTrackMenuItems.map(item => renderFastTrackDesktopMenuItem(item))
  };

  const renderFastTrackDesktopMenu = () => {
    if (!fastTrackPortalsEnabled) {
      return;
    }
    return (
        <div className={css.fastTrack}>
          <div className={css.labelWrapper}>
            <img src={FastTrackLogo} alt=""/>
          </div>
          {renderFastTrackDesktopMenuItems()}
        </div>
        
    );
  };

 
  return (
    <Layout>
      <SEO title={pageTitle} description={'Solutions'}/>

      <div className={css.wrapper}>

        <div className={css.About}>
          
        <div className={css.wrapper}>
        <div className={css.logoWrapper}>
            <img src={OptiwAIPlusLogo} alt="" />
        </div>
        <div className={css.titleWrapper}>
            Delivering professional photo workflow processing automation
        </div>
        <div className={css.textWrapper}>
            <p>Save time and money on repetitive and redundant imaging tasks</p>
        </div>
        <div className={css.buttonWrapper}>
            {optiPlusLoggingEnabled && <SignUpButton application={'OptiwaiPlus'} label="Try for free" className={css.button}/> }
        </div>
        <div className={css.gifsWrapper}>
            {renderGifWrapper(1, WorkflowAnimation1Img, "Single operation", "functionality, pick&use")}
            {renderGifWrapper(2, WorkflowAnimation2Img, "Predefined workflows", "dedicated functionalities")}
            {renderGifWrapper(3, WorkflowAnimation3Img, "Customized workflow", "personalized functionalities")}
        </div>
        <div className={css.portal}>
          {renderFastTrackDesktopMenu()}
        </div>
         
        <div className={css.flowWrapper}>
            <img src={About1Img} alt="" className={css.desktopImage} />
            <img src={About1MobileImg} alt="" className={css.mobileImage} />
        </div>

        <div className={css.OptiplusExploreHero}>
            <OptiplusExploreHero />
        </div>

        <div className={css.FaceCroppingOptiplus}>
            <FaceCroppingOptiplus />
        </div>

        <div >
          <VerticalLine/>
        </div>

        <div className={css.AgedPhotoOptiplus}>
            <AgedPhotoOptiplus/>
        </div>

        <div >
          <VerticalLine/>
        </div>

        <div className={css.OptimizationOptiplus}>
            <OptimizationOptiplus/>
        </div>

        <div >
          <VerticalLine/>
        </div>
 
        <div className={css.ArtifactRemoving}>
            <ArtifactRemoving/>
        </div>

        <div >
          <VerticalLine/>
        </div>
       
        <div className={css.Upscaling}>
            <Upscaling/>
        </div>

        <div >
          <VerticalLine/>
        </div>

        <div className={css.AnonymisationOptiplus}>
            <AnonymisationOptiplus/>
        </div>

        <div >
          <VerticalLine/>
        </div>

        <div className={css.AutoCroppingOptiplus}>
            <AutoCroppingOptiplus/>
        </div>

        <div >
          <VerticalLine/>
        </div>
        
        <div className={css.MetadataOptiplus}>
            <MetadataOptiplus />
        </div>

        <div >
          <VerticalLine/>
        </div>

        <div className={css.RotationSizeModificationOptiplus}>
            <RotationSizeModificationOptiplus />
        </div>

        <div >
          <VerticalLine/>
        </div>

        <div className={css.FileFormatsConversionOptiplus}>
            <FileFormatsConversion />
        </div>
        
        </div>

        </div>

        <div className={css.Footer}>
          <Footer />
        </div>

      </div>
    </Layout>
  );
};

export default SolutionsPage;
