import React from 'react';
import css from './siteDescription.module.scss';
import PageTitle from "../PageTitle/PageTitle";
import SignUpButton from "../SignUpButton/SignUpButton";

import {viewports} from "../../componentsUtils";
import useBreakpoint from "../../hooks/useBreakpoint";

const SiteDescription = (props) => {
  const { title = null, content = '', width = 530, application, button, buttonShown = true } = props;

  const breakpoint = useBreakpoint();

  const isPhoneViewport = (breakpoint.viewport === viewports.phoneViewport);

  const renderButton = () => {
    if (!buttonShown) {
        return null;
    }
    if (button) {
        return button;
    }
    return null;
    // return <SignUpButton application={application} label={"Try for free"} bigger={isPhoneViewport} />;
  };

  return (
    <div className={css.featuredTextWrapper} style={{ width }}>
      {title &&
        <PageTitle application={application} phoneViewport={isPhoneViewport}>{title}</PageTitle>
      }
      <div className={css.featuredText}>
        {content}
      </div>
      <div className={css.signUpWrapper}>
          {renderButton()}
      </div>
    </div>
  );
};

export default SiteDescription;
