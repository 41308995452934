import React from 'react';
import css from "./textContent.module.scss";

const TextContent = props => {
  return (
    <div className={css.textContent}>
      {props.children}
    </div>
  );
};

export default TextContent;

