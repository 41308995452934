import React from 'react';
import css from "./fileFormatsConversionOptiplus.module.scss";
import { useTranslation} from 'react-i18next';
import {
  IllustratedBox,
  ImagesContent,
  ImagesWrapper,
  Text,
  TextContent,
  TextHeader,
  TextWrapper
} from "../../shared/IllustratedBox/IllustratedBox";
import useBreakpoint from "../../hooks/useBreakpoint";
import FileFormatImage from "../../../images/sections/fileFormatsConversion/file_format.svg";
import {viewports} from "../../componentsUtils";
import useScale from "../../hooks/useScale";
import BoxHeader from "../../shared/BoxHeader/BoxHeader";

const FileFormatsConversion = () => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const { phoneViewport, tabletViewport, desktopViewport } = viewports;
  const currentViewport = breakpoint.viewport;

  const scaleSettings = {
    [phoneViewport]: {
      baseScale: 1.6,
      maxScale: 0.7
    },
    [tabletViewport]: {
      baseScale: 1.2,
      maxScale: 0.85
    },
    [desktopViewport]: {
      baseScale: 0.85,
      maxScale: null
    }
  };

  const scale = useScale(scaleSettings[currentViewport].baseScale, scaleSettings[currentViewport].maxScale);

  const viewportSettings = {
    [phoneViewport]: {
      imagesPosition: 'top',
      textWrapperProps: {
        padding: '60px 20px 40px 20px'
      },
      imagesWrapperProps: {
        top: 20
      },
      imagesContentProps: {
        width: 600 * scale,
        height: 521 * scale,
      },
      boxHeaderPlace: 'top'
    },
    [tabletViewport]: {
      imagesPosition: 'top',
      textWrapperProps: {
        padding: '50px 16% 0 8%'
      },
      imagesWrapperProps: {
        top: 10
      },
      imagesContentProps: {
        width: 600 * scale,
        height: 521 * scale,
      },
      boxHeaderPlace: 'top'
    },
    [desktopViewport]: {
      imagesPosition: 'left',
      textWrapperProps: {
        padding: '30px 0 0 74px'
      },
      imagesWrapperProps: {
        top: 40
      },
      imagesContentProps: {
        width: 600 * scale,
        height: 521 * scale,
      },
      boxHeaderPlace: 'text'
    },
  };

  const {
    imagesPosition,
    textWrapperProps,
    imagesWrapperProps,
    imagesContentProps,
    boxHeaderPlace,
  } = viewportSettings[currentViewport];

  const imagesBox = (
    <ImagesWrapper {...imagesWrapperProps}>
      <ImagesContent {...imagesContentProps} className={css.imagesContent}>
        <img src={FileFormatImage} alt={'file formats conversion'} />
      </ImagesContent>
    </ImagesWrapper>
  );

  const header = <>File Formats <br/>Conversion</>;

  const textBox = (
    <TextWrapper {...textWrapperProps}>
      {boxHeaderPlace === 'text' && <BoxHeader headerType={'h2'} lineColor={'green'} style={{ marginBottom: 30, marginTop: 70 }}>{header}</BoxHeader>}
      <TextContent>
        <TextHeader>
          <h3></h3>
        </TextHeader>
        <Text>
        {(<div className={css.content}><p><strong><app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak></strong> provides automated operation for converting <br/>photos to the desired file format type,<br/> including web special formats.</p><p>It is perfectly suitable for photo file formats <br/>standarisation appropriate to the needs especially when <br/>processing large numbers of files.</p></div>)}
        </Text>
      </TextContent>
    </TextWrapper>
  );

  return (
    <IllustratedBox
      boxHeader={boxHeaderPlace === 'top' && header}
      boxHeaderLineColor={'green'}
      imagesBox={imagesBox}
      textBox={textBox}
      imagesPosition={imagesPosition}
    />
  );
};

export default FileFormatsConversion;
