import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import css from "./upscaling.module.scss";
import {useTranslation} from 'react-i18next';
import {
  IllustratedBox,
  ImagesContent,
  ImagesWrapper,
  Text,
  TextContent,
  TextHeader,
  TextWrapper
} from "../../shared/IllustratedBox/IllustratedBox";
import useBreakpoint from "../../hooks/useBreakpoint";
import {viewports} from "../../componentsUtils";
import useScale from "../../hooks/useScale";
import BoxHeader from "../../shared/BoxHeader/BoxHeader";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_upscaling"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 800, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Upscaling = () => {
  const { t } = useTranslation();
  const imagesFluid = getImagesFluid(useStaticQuery(query));
  const breakpoint = useBreakpoint();
  const { phoneViewport, tabletViewport, desktopViewport } = viewports;
  const currentViewport = breakpoint.viewport;

  const scaleSettings = {
    [phoneViewport]: {
      baseScale: 1.6,
      maxScale: 0.7
    },
    [tabletViewport]: {
      baseScale: 1.2,
      maxScale: 0.85
    },
    [desktopViewport]: {
      baseScale: 0.85,
      maxScale: null
    }
  };

  const scale = useScale(scaleSettings[currentViewport].baseScale, scaleSettings[currentViewport].maxScale);

  const viewportSettings = {
    [phoneViewport]: {
      imagesPosition: 'top',
      textWrapperProps: {
        padding: '60px 20px 40px 20px'
      },
      imagesWrapperProps: {
        top: 20
      },
      imagesContentProps: {
        width: 600 * scale,
        height: 521 * scale,
      },
      boxHeaderPlace: 'top'
    },
    [tabletViewport]: {
      imagesPosition: 'top',
      textWrapperProps: {
        padding: '50px 16% 0 8%'
      },
      imagesWrapperProps: {
        top: 10
      },
      imagesContentProps: {
        width: 600 * scale,
        height: 521 * scale,
      },
      boxHeaderPlace: 'top'
    },
    [desktopViewport]: {
      imagesPosition: 'right',
      textWrapperProps: {
        padding: '60px 74px 0 0'
      },
      imagesWrapperProps: {
        top: 40
      },
      imagesContentProps: {
        width: 600 * scale,
        height: 521 * scale,
      },
      boxHeaderPlace: 'text'
    },
  };

  const {
    imagesPosition,
    textWrapperProps,
    imagesWrapperProps,
    imagesContentProps,
    boxHeaderPlace,
  } = viewportSettings[currentViewport];

  const imagesBox = (
    <ImagesWrapper {...imagesWrapperProps}>
      <ImagesContent {...imagesContentProps} className={css.imagesContent}>
        {renderImageByFileName(imagesFluid, 'upscaling.png', 'upscaling')}
      </ImagesContent>
    </ImagesWrapper>
  );

  const header = "Upscaling";

  const textBox = (
    <TextWrapper {...textWrapperProps}>
      {boxHeaderPlace === 'text' && <BoxHeader headerType={'h2'} lineColor={'green'} style={{ marginBottom: 30 }}>{header}</BoxHeader>}
      <TextContent>
        <TextHeader>
          <h3></h3>
        </TextHeader>
        <Text>
        {(<div className={css.content}><p>Converting lower resolution photos to a higher selected level, selectively x2, x4, x8, x16 - up to 8K resolution.</p><p>With new <strong><app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak></strong> upscaling techniques, the enhanced <br/>visuals look more crisp and realistic than ever.<br/><br/> <strong><app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak></strong> can produce incredible sharpness and <br/>enhanced details no traditional scaler can recreate.</p></div>)}
        </Text>
      </TextContent>
    </TextWrapper>
  );

  return (
    <IllustratedBox
      boxHeader={boxHeaderPlace === 'top' && header}
      boxHeaderLineColor={'green'}
      imagesBox={imagesBox}
      textBox={textBox}
      imagesPosition={imagesPosition}
    />
  );
};

export default Upscaling;
