import React from 'react';
import css from './textHeader.module.scss';

const TextHeader = (props) => {
  return (
    <div className={css.textHeader}>
      {props.children}
    </div>
  );
};

export default TextHeader;
