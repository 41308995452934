import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './optiplusExploreHero.module.scss';
import {
  Background,
  IllustratedBox,
  ImagesContent,
  ImagesWrapper,
  TextContent,
  TextWrapper
} from "../../shared/IllustratedBox/IllustratedBox";
import SiteDescription from '../../shared/SiteDescription/SiteDescription';
import HeroImage from "../../../images/sections/SolutionsSection/explore_hero.svg";
import useBreakpoint from "../../hooks/useBreakpoint";
import {viewports} from "../../componentsUtils";
import useScale from "../../hooks/useScale";
// import {optiPlusLoggingEnabled} from "../../shared/routes";

const SolutionsSection = () => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const { phoneViewport, tabletViewport, desktopViewport } = viewports;
  const currentViewport = breakpoint.viewport;

  const scaleSettings = {
    [phoneViewport]: {
      baseScale: 0.95,
      maxScale: 0.35
    },
    [tabletViewport]: {
      baseScale: 0.95,
      maxScale: 0.55
    },
    [desktopViewport]: {
      baseScale: 0.89,
      maxScale: null
    }
  };

  const scale = useScale(scaleSettings[currentViewport].baseScale, scaleSettings[currentViewport].maxScale);

  const viewportSettings = {
    [phoneViewport]: {
      textWrapperProps: {
        padding: '20px 8% 0 8%'
      },
      imagesPosition: 'top',
      imagesWrapperProps: {
        top: 140 * scale
      },
      imagesContentProps: {
        width: 780 * scale,
        height: 840 * scale,
      },
      imagesBackgroundProps: {
        top: 110 * scale,
        right: -152 * scale,
        width: 1062 * scale,
      }
    },
    [tabletViewport]: {
      textWrapperProps: {
        padding: '20px 16% 0 8%'
      },
      imagesPosition: 'top',
      imagesWrapperProps: {
        top: 0,
      },
      imagesContentProps: {
        width: 800 * scale,
        height: 700 * scale,
      },
      imagesBackgroundProps: {
        top: 80 * scale,
        left: -126 * scale,
        width: 1062 * scale,
      }
    },
    [desktopViewport]: {
      textWrapperProps: {
        padding: '70px 0 0 0'
      },
      imagesPosition: 'right',
      imagesWrapperProps: {
        top: 0,
        right: 0
      },
      imagesContentProps: {
        width: 630 * scale,
        height: 520 * scale,
      },
      imagesBackgroundProps: {
        top: 40 * scale,
        right: -40 * scale,
        width: 740 * scale,
      }
    }
  };

  const {
    textWrapperProps,
    imagesPosition,
    imagesWrapperProps,
    imagesContentProps,
    imagesBackgroundProps
  } = viewportSettings[currentViewport];

  const imagesBox = (
    <ImagesWrapper {...imagesWrapperProps}>
      <Background {...imagesBackgroundProps}>
        <img src={HeroImage} alt={'opti optiwai plus upscaling inpainting artifact removing optimisation ocr object recognition'} />
      </Background>
      <ImagesContent {...imagesContentProps} />
    </ImagesWrapper>
  );

  const textBox = (
    <div className={css.siteDescriptionWrapper}>
      <TextWrapper {...textWrapperProps}>
        <TextContent>
          <SiteDescription
            // title={<h1>Explore</h1>}
            content={(<div className={css.content}><p>Become a professional in improving your image quality <br/>No complexity, No need for a training <br/><br/>Simply enjoyable by All<br/> Let’s understand how the AI work for us.</p></div>)}
            // application={'OptiwaiPlus'}
            buttonShown= {false}
            width={'100%'}
          />
        </TextContent>
      </TextWrapper>
    </div>
  );

  return (
    <div className={css.wrapper}>
      <IllustratedBox
        imagesBox={imagesBox}
        textBox={textBox}
        imagesPosition={imagesPosition}
      />
    </div>
  );
};

export default SolutionsSection;




