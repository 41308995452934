import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import css from "./rotationSizeModificationOptiplus.module.scss";
import {useTranslation} from 'react-i18next';
import {
  IllustratedBox,
  ImagesContent,
  ImagesWrapper,
  Text,
  TextContent,
  TextHeader,
  TextWrapper
} from "../../shared/IllustratedBox/IllustratedBox";
import useBreakpoint from "../../hooks/useBreakpoint";
import {viewports} from "../../componentsUtils";
import useScale from "../../hooks/useScale";
import BoxHeader from "../../shared/BoxHeader/BoxHeader";


const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_rotationSizeModificationOptiplus"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 900, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const RotationSizeModificationOptiplus = () => {
  const { t } = useTranslation();
  const imagesFluid = getImagesFluid(useStaticQuery(query));
  const breakpoint = useBreakpoint();
  const { phoneViewport, tabletViewport, desktopViewport } = viewports;
  const currentViewport = breakpoint.viewport;

  const scaleSettings = {
    [phoneViewport]: {
      baseScale: 1,
      maxScale: 0.6
    },
    [tabletViewport]: {
      baseScale: 1.2,
      maxScale: 0.6
    },
    [desktopViewport]: {
      baseScale: 0.6,
      maxScale: null
    }
  };

  const scale = useScale(scaleSettings[currentViewport].baseScale, scaleSettings[currentViewport].maxScale);

  const viewportSettings = {
    [phoneViewport]: {
      imagesPosition: 'top',
      textWrapperProps: {
        padding: '60px 20px 40px 20px'
      },
      imagesWrapperProps: {
        top: 20
      },
      imagesContentProps: {
        width: 839 * scale,
        height: 713 * scale,
      },
      boxHeaderPlace: 'top'
    },
    [tabletViewport]: {
      imagesPosition: 'top',
      textWrapperProps: {
        padding: '50px 16% 0 8%'
      },
      imagesWrapperProps: {
        top: 10
      },
      imagesContentProps: {
        width: 839 * scale,
        height: 713 * scale,
      },
      boxHeaderPlace: 'top'
    },
    [desktopViewport]: {
      imagesPosition: 'right',
      textWrapperProps: {
        padding: '60px 74px 0 0'
      },
      imagesWrapperProps: {
        top: 40
      },
      imagesContentProps: {
        width: 839 * scale,
        height: 713 * scale,
      },
      boxHeaderPlace: 'text'
    },
  };

  const {
    imagesPosition,
    textWrapperProps,
    imagesWrapperProps,
    imagesContentProps,
    boxHeaderPlace,
  } = viewportSettings[currentViewport];

  const imagesBox = (
    <ImagesWrapper {...imagesWrapperProps}>
      <ImagesContent {...imagesContentProps} className={css.imagesContent}>
        {renderImageByFileName(imagesFluid, 'rotationSizeModification.png', 'rotation size modification')}
      </ImagesContent>
    </ImagesWrapper>
  );

  const header = <>Rotation,<br/> Size Modification</>;

  const textBox = (
    <TextWrapper {...textWrapperProps}>
      {boxHeaderPlace === 'text' && <BoxHeader headerType={'h2'} lineColor={'green'} style={{ marginBottom: 30 }}>{header}</BoxHeader>}
      <TextContent>
        <TextHeader>
          <h3></h3>
        </TextHeader>
        <Text>
        {(<div className={css.content}><p><strong><app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak> </strong>provides automatisation of simple<br/> operations like changing photo rotation or size.</p><p>This is perfect for standardising a large number of <br/>images to a fixed rotation/size, especially for<br/> a large number of images to be processed.</p></div>)}
        </Text>
      </TextContent>
    </TextWrapper>
  );

  return (
    <IllustratedBox
      boxHeader={boxHeaderPlace === 'top' && header}
      boxHeaderLineColor={'green'}
      imagesBox={imagesBox}
      textBox={textBox}
      imagesPosition={imagesPosition}
    />
  );
};

export default RotationSizeModificationOptiplus;
